<template>
    <div class="relative isolate px-6 pt-14 lg:px-8 ">
        <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true">
            <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)">
            </div>
        </div>
        <div class="mx-auto max-w-4xl py-6 sm:py-48 lg:py-36">
            <div class="hidden sm:mb-8 sm:flex sm:justify-center">
                <div
                    class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    {{ $t('landingPageRecentNewsAnnouncement') }}
                    <NuxtLink to="/onboard/tudoc" class="font-semibold text-indigo-600"><span class="absolute inset-0"
                            aria-hidden="true"></span>
                        {{ $t('landingPageRecentNewsCallToAction') }} <span aria-hidden="true">&rarr;</span></NuxtLink>
                </div>
            </div>
            <div class="text-left sm:text-center">
                <h1 class="text-3xl font-medium tracking-tight text-gray-900 sm:text-6xl">
                    <!-- {{ $t('landingPageMainHero')}} -->
                    Find local doctors<br />who take global insurance
                </h1>
                <!-- <p class="mt-6 text-lg leading-8 text-gray-600">{{ $t('landingPageMainHeroSubHeading') }}</p> -->
                <!-- <div class="mt-10 flex items-center justify-center gap-x-6">
                    <UButton to="/onboard/" size="lg" class="font-semibold">{{ $t('getStarted') }}</UButton>
                    <UButton to="/find-care/mexico-city" size="lg" variant="soft" color="white"
                        icon="i-heroicons-arrow-right" trailing class="font-semibold">
                        {{ $t('learnMore') }}</UButton>
                </div> -->

            </div>
            <div class="mt-10 flex justify-center">
                <form class="w-full max-w-md">
                    <div class="flex gap-x-4">
                        <USelectMenu class="w-full" v-model="selectedCityId" size="xl" :options="citiesCollection"
                            placeholder="Find providers in your city" value-attribute="slug" option-attribute="name" />
                        <UButton :to="`/find-care/${selectedCityId}`" size="lg">Find providers</UButton>
                    </div>
                    <!-- <p class="mt-4 text-sm leading-6 text-gray-900">We care about your data. Read our <a href="#"
                            class="font-semibold text-indigo-600 hover:text-indigo-500">privacy&nbsp;policy</a>.</p> -->
                </form>
            </div>
            <div class="py-24 sm:py-32">
                <div
                    class="mx-auto items-center max-w-none grid grid-cols-3 gap-x-8 gap-y-10 sm:max-w-none sm:grid-cols-6 sm:gap-x-12 sm:gap-y-14 grayscale">
                    <img class="max-h-12 w-full object-contain" src="~/assets/svg/aetna.svg" alt="Aetna International"
                        width="158" height="48" />
                    <img class="max-h-12 w-full object-contain" src="~/assets/svg/allianz.svg" alt="Allianz Care"
                        width="158" height="48" />
                    <img class="max-h-12 w-full object-contain" src="~/assets/svg/cigna.svg" alt="Cigna Healthcare"
                        width="158" height="48" />
                    <img class=" max-h-12 w-full object-contain" src="~/assets/svg/bcbs_fep.svg"
                        alt="Blue Cross Blue Shield Federal Employee Program" width="158" height="48" />
                    <img class=" max-h-12 w-full object-contain" src="~/assets/svg/geoblue2.svg" alt="GeoBlue"
                        width="158" height="48" />
                    <img class=" max-h-12 w-full object-contain" src="~/assets/svg/va.svg" alt="GeoBlue" width="158"
                        height="48" />
                </div>
                <div class="mt-16 flex justify-center">
                    <p
                        class="relative rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
                        <span class="hidden md:inline">
                            NomadRx works with most global insurance companies.</span>
                        <NuxtLink to="/blog" class="font-semibold text-indigo-600">
                            <span class="absolute inset-0" aria-hidden="true" /> Read more <span
                                aria-hidden="true">&rarr;</span>
                        </NuxtLink>
                    </p>
                </div>
            </div>
        </div>
        <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true">
            <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)">
            </div>

        </div>
    </div>

    <SimpleCenteredFooter class="z-20" />

    <!--  <CtaDirectClaims class="mb-20" />-->
    <!--  <PatientsDirectPricingSectionTwoTiers>-->

    <!--  </PatientsDirectPricingSectionTwoTiers>-->
</template>

<script setup lang="ts">
import SimpleCenteredFooter from "~/components/footer/SimpleCenteredFooter.vue";

const { citiesCollection } = useDataLookups();

definePageMeta({
    layout: 'default',
    middleware: [],
});

const selectedCityId = ref('mexico-city')
const selectedCity = computed(() => {
    return citiesCollection.find((city) => city.slug == selectedCityId.value);
})
useSeoMeta({
    title: () => `NomadRx | Your international health insurance, accepted anywhere in world`,
    description: () => `With NomadRx, easily use your health insurance anywhere in the world. No upfront payments, no claims, or slow reimbursements—just a more responsible way to get healthcare abroad.`,
    ogTitle: () => `NomadRx | Your international health insurance, accepted anywhere in world`,
    ogDescription:
        `With NomadRx, easily use your health insurance anywhere in the world. No upfront payments, no claims, or slow reimbursements—just a more responsible way to get healthcare abroad.`,
    ogImage: null,
    ogUrl: () => `https://www.nomadrx.co/`,
});

</script>

<style scoped>
svg:hover {
    fill: red;
}
</style>